/* CSR Background */
.csr-background {
  width: 100%;
}

@media (max-width: 600px) {
  .csr-background {
    height: 100vh;
  }
}
