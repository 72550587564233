/* CSR Header */
.csr-header {
  background-color: #232323;
  display: flex;
  justify-content: space-between;
}

.csr-logo {
  height: 2.8rem;
  margin: 2rem;
}

.hamburger-icon {
  height: 2.8rem;
  margin: 2rem;
}

@media (max-width: 600px) {
  .csr-logo {
    height: 2rem;
    margin: 1rem;
  }

  .hamburger-icon {
    height: 2rem;
    margin: 1rem;
  }
}
