.csr-marquee {
  background-color: red;
  color: white;
}

.csr-marquee h1 {
  font-size: 1rem;
  margin: 0;
  padding: 0.5rem 2rem;
}
