.admin-dashboard-button {
  background-color: #232323;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #ff4d4d;
  }

  &:active {
    background-color: #cc0000;
    transform: scale(0.95);
  }
}

.admin-dashboard-header {
  text-align: left;
  font-size: 1.6rem;
  font-weight: bold;
  color: #232323;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #ccc;
  padding-bottom: 0.75rem;
}

.admin-dashboard-location-header {
  text-align: left;
  font-size: 1.3rem;
  font-weight: bold;
  color: #232323;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #ccc;
  padding-bottom: 0.75rem;
}

.admin-dashboard-location-messages-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex;
}

.admin-dashboard-title {
  margin-bottom: -2.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: #232323;
}

.admin-dashboard-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2.5rem;
}

ul {
  list-style-type: none;
  text-align: left;
  padding-left: 0.1rem;
  margin-top: -1rem;
}

li {
  margin-right: 1rem;
  padding: 0.2rem 0rem;

  span {
    padding-right: 1rem;
  }
}

input {
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 1rem;
}

label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: block;
  text-align: left;
}

table {
  width: calc(100vw - 2.5rem - 6%);
  border-collapse: collapse;
  margin-top: 0.5rem 0rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  td:last-child {
    text-align: center;
    width: 130px;
  }

  @media screen and (max-width: 600px) {
    width: calc(100vw - 2.5rem - 12%);
  }
}
